<template>
    <div
    @mouseover="mouseActionLayout('hover', layout)"
    @mouseleave="mouseActionLayout('leave', layout)"
    class="layout-wrapper"
    :class="[
        layout.class + ' ' + layout.props.class,
        {
            'layout-active': selectedElement !== undefined && selectedElement.id === layout.id,
        },
    ]"
    >
    <div class="sk-layout-wrapper">
            <div class="position-absolute" v-if="layout && layout.style && layout.style.customCss" v-html="'<style>' + layout.style.customCss + '</style>'"></div>
            <div class="layout" :class="[{ 'not-empty': layout && layout.childs && layout.childs.length > 0 }]" :style="styleObject">
                <!-- Elements In Layout -->
                <draggable :emptyInsertThreshold="100" @add="onElementAdd" class="dragArea list-group w-full" :list="layout.childs" group="element" @change="onChange" handle=".move-element">
                    <div @mouseover="mouseActionElement('hover', e)" @mouseleave="mouseActionElement('leave', e)" class="element-wrapper" :class="{ 'hovered-element': elementHover === e.id }" v-for="e in layout.childs" :key="e.id">
                        <!-- #Element information and action buttons -->
                        <div class="sk-element">
                            <component :class="[{ 'sk-element-active': selectedElement.id === e.id }]" v-on:onClick="selectElement($event)" v-on:htmlValue="htmlValue($event, e)" :is="e.type" :element="e" />
                            <div class="sk-element-overlay" @click.stop="selectElement(e)" v-if="elementHover === e.id && hoverActive">
                                <div class="ps-3">
                                    <p class="p-0 m-0 ms-1 text-white" v-if="elementHover === e.id">{{ e.id }}</p>
                                </div>
                                <div class="d-flex justify-content-end align-items-center pe-3">
                                    <button class="twpb-btn move-element position-absolute text-white bg-dark rounded-circle" style="right: 10px; top: -20px; z-index: 999">
                                        <i class="fas fa-arrows-alt"></i>
                                    </button>
                                    <button class="twpb-btn twpb-btn-primary twpb-btn-sm action-button select-element-btn me-1" @click.stop="selectElement(e)">
                                        <i class="fas fas fa-pen"></i>
                                    </button>
                                    <button class="twpb-btn twpb-btn-secondary twpb-btn-sm action-button clone-element-btn me-1" @click.stop="cloneElement(e, layout)">
                                        <i class="fas fas fa-copy"></i>
                                    </button>
                                    <button class="twpb-btn twpb-btn-danger twpb-btn-sm action-button remove-element-btn" @click.stop="removeElement(e)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
                <!-- #Elements In Layout -->
            </div>
            <!-- Layout Overlay -->
            <div class="sk-layout-overlay" @click.stop="selectElement(layout)" v-if="layoutHover === layout.id && hoverActive">
                <div class="d-flex justify-content-start align-items-center h-100 flex-column">
                    <div class="text-white move-column">
                        <i class="fas fa-arrows-alt"></i>
                    </div>

                    <div class="d-flex justify-content-center align-items-center flex-column">
                        <div class="text-primary mb-1 mt-2" title="Edit Layout" @click.stop="selectElement(layout)">
                            <i class="fas fas fa-pen"></i>
                        </div>
                        <div class="text-white mb-1" title="Copy Layout" @click.stop="cloneColumn(layout)">
                            <i class="fas fas fa-copy"></i>
                        </div>
                        <div class="text-danger" title="Delete Layout" @click.stop="removeColumn(layout)">
                            <i class="fas fa-trash-alt"></i>
                        </div>
                    </div>
                </div>
                <small class="layout-id"><i :title="layout.id" class="fas fa-info"></i></small>
            </div>
            <!-- #Layout Overlay -->
        </div>
    </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";

import Paragraph from "@/components/page-builder/elements/default/paragraph/Paragraph";
import Heading from "@/components/page-builder/elements/default/heading/Heading";
import Button from "@/components/page-builder/elements/default/button/Button";
import Photo from "@/components/page-builder/elements/default/photo/Photo";
import Slider from "@/components/page-builder/elements/default/slider/Slider";
import Accordion from "@/components/page-builder/elements/default/accordion/Accordion";
import Tab from "@/components/page-builder/elements/default/tab/Tab";
import ContentSlider from "@/components/page-builder/elements/default/content-slider/ContentSlider";
import Gallery from "@/components/page-builder/elements/default/gallery/Gallery";
import Html from "@/components/page-builder/elements/default/html/Html";
import Callout from "@/components/page-builder/elements/default/callout/Callout";
import Textblock from "@/components/page-builder/elements/default/textblock/Textblock";
import Separator from "@/components/page-builder/elements/default/separator/Separator";
import ContactForm from "@/components/page-builder/elements/default/contact-form/ContactForm";
export default {
    name: "Column",
    components: {
        draggable: VueDraggableNext,
        Paragraph,
        Heading,
        Button,
        Photo,
        Slider,
        Accordion,
        Tab,
        ContentSlider,
        Gallery,
        Html,
        Callout,
        Textblock,
        Separator,
        ContactForm,
    },
    props: {
        layout: Object,
        childs: Array,
        selectedElement: Object,
        hoverActive: Boolean,
    },
    data: () => ({
        layoutHover: false,
        elementHover: false,
    }),
    methods: {
        enableEdit(e) {
            e.edit = true;
        },
        disableEdit(e) {
            e.edit = false;
        },
        onChange(evt) {
            if (evt.added) {
                this.$emit("openSettings", evt.added.element);
            }
        },
        removeColumn(column) {
            this.$emit("removeColumn", column.id);
        },
        cloneColumn(column) {
            this.$emit("cloneColumn", column);
        },
        removeElement(element) {
            this.$emit("removeElement", {
                elementId: element.id,
                layoutId: this.layout.id,
            });
        },
        selectElement(element) {
            this.$emit("selectElement", element);
        },
        cloneElement(element, layout) {
            this.$emit("cloneElement", { element: element, layout: layout });
        },

        // Element dropped into layout, find element and add replace it with new values
        onElementAdd(evt) {
            let index = evt.newIndex;
            let newElement = null;

            // Add paragraph element
            newElement = {
                id: this.layout.childs[index].type + "-" + Date.now(),
                type: this.layout.childs[index].type,
                props: {
                    ...this.layout.childs[index].props,
                },
                style: {
                    ...this.layout.childs[index].style,
                },
            };

            this.$emit("addElement", {
                layoutId: this.layout.id,
                elementIndex: index,
                element: newElement,
            });
        },

        mouseActionLayout(type, layout) {
            if (type === "hover") {
                this.layoutHover = layout.id;
            } else {
                this.layoutHover = "";
            }
        },
        mouseActionElement(type, element) {
            if (type === "hover") {
                this.elementHover = element.id;
            } else {
                this.elementHover = "";
            }
        },
        htmlValue(event, element) {
            element.props.value = event;
        },
    },
    computed: {
        styleObject() {
            return {
                backgroundImage: this.layout.props.backgroundImage && this.layout.props.backgroundImage !== "" ? "url(" + this.layout.props.backgroundImage + ")" : "none",
                backgroundSize: this.layout.style.backgroundSize ? this.layout.style.backgroundSize : "unset",
                backgroundPosition: this.layout.style.backgroundPosition ? this.layout.style.backgroundPosition : "unset",
                backgroundRepeat: this.layout.style.backgroundRepeat ? this.layout.style.backgroundRepeat : "unset",
                backgroundColor: this.layout.style.backgroundColor ? this.layout.style.backgroundColor : "",
                padding: this.layout.style.padding,
                margin: this.layout.style.margin,
                borderRadius: this.layout.style.borderRadius,
                borderWidth: this.layout.style.borderWidth && this.layout.style.borderWidth != "0%" && this.layout.style.borderWidth != "0px" && this.layout.style.borderWidth != "0rem" ? this.layout.style.borderWidth : "auto",
                borderStyle: this.layout.style.borderStyle ? this.layout.style.borderStyle : "unset",
                borderColor: this.layout.style.borderColor ? this.layout.style.borderColor : "unset",
            };
        },
    },

    created() {},
    emits: ["onClick", "addElement", "selectElement", "removeColumn", "openSettings", "removeElement", "cloneColumn", "htmlValue"],
};
</script>

<style>
.layout-wrapper {
    /* padding-bottom: 1rem; */
}

.layout {
    min-height: 100px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.layout-data {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding-right: 5px;
}

.layout-action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.action-button {
    padding: 1px 5px;
    border-radius: 50%;
}

.element-wrapper.hovered-element .sk-not-selected-element {
    color: white;
}

.element-info {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    position: relative;
    margin-top: -14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}

.element-data {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.element-action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.element-wrapper img {
    max-width: 100%;
}

.sk-element {
    position: relative;
}

.sk-element-overlay {
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-left: 20px;
    z-index: 999;
}

.sk-element-active::after {
    content: "Selected";
    width: 15px;
    z-index: 999;
    height: 20px;
    position: absolute;
    right: -7px;
    top: 0;
    writing-mode: vertical-lr;
    justify-content: center;
    align-items: center;
    background-color: #4e6078;
    color: white;
    height: 100%;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
}

.sk-layout-wrapper {
    position: relative;
}

.sk-layout-overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: -12px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    width: 24px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-top: 5px;
    font-size: 12px;
}

.sk-layout-overlay .layout-id {
    font-size: 10px;
    writing-mode: vertical-lr;
    color: white;
    height: 16px;
    width: 16px;
    background-color: #4e6078;
    border-radius: 50%;
    padding: 3px;
    margin-bottom: 5px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sk-layout-overlay-active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: -12px;
    height: 100%;
    background-color: #4e6078;
    width: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-top: 5px;
    font-size: 10px;
    writing-mode: vertical-rl;
    color: white;
}
</style>
